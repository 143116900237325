export function formatReadingTime(minutes: number): string {
  return `${minutes} min read`;
}

export function formatPostDate(date: Date): string {
  if (typeof Date.prototype.toLocaleDateString !== 'function') {
    return '';
  }

  date = new Date(date);
  return date.toLocaleDateString('ko-KR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}
