import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { variables } from '../lib/styles/variables';
import { breakpoints } from '../lib/styles/responsive';

const HeaderBlock = styled.header`
  padding: 32px;

  @media (max-width: ${breakpoints.medium}) {
    padding: 24px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${variables.maxWidth.header};
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`;

const Profile = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-decoration: none;
`;

const Name = styled.div`
  font-size: 24px;
  font-weight: 900;
  color: ${variables.color};
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledLink = styled(Link)`
  margin-left: 32px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.56);
  text-decoration: none;
`;

export interface HeaderProps {}

const Header = ({}: HeaderProps) => (
  <HeaderBlock>
    <ContentWrapper>
      <Profile to="/">
        <Name>Youngjin Kang</Name>
      </Profile>
      <Links>
        {/* <StyledLink to="/posts">전체 포스트</StyledLink> */}
        {/* <StyledLink to="/about">소개</StyledLink> */}
      </Links>
    </ContentWrapper>
  </HeaderBlock>
);

export default Header;
