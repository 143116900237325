/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import GlobalStyles from '../lib/styles/globalStyles';
import Header from './Header';
import { variables } from '../lib/styles/variables';
import { breakpoints } from '../lib/styles/responsive';

const LayoutBlock = styled.div`
  background-color: ${variables.backgroundColor};
  color: ${variables.color};
  min-height: 100vh;
`;

const Main = styled.main`
  width: 100%;
  max-width: ${variables.maxWidth.post};
  margin: 0 auto;
  padding: 32px;

  @media (max-width: ${breakpoints.medium}) {
    padding: 24px;
  }
`;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <LayoutBlock>
      <GlobalStyles />
      <Header />
      <Main>{children}</Main>
    </LayoutBlock>
  );
};

export default Layout;
