export const variables = {
  maxWidth: {
    post: '680px',
    header: '1080px',
  },
  backgroundColor: 'rgb(47, 52, 55)',
  color: 'rgba(255, 255, 255, 0.9)',
  primaryColor: '#00BFA5',
  linkColor: '#649cce',
};
